import Link from "next/link";
import React from "react";
import LogoWithTitle from "../components/bits/LogoWithTitle";
import TextLink from "../components/bits/TextLink";

const Custom404 = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <LogoWithTitle hideTitle />
      <h1 className="text-lg font-semibold">
        Sorry! <br />
        We Didn&apos;t found the Page Request
      </h1>
      <TextLink>
        <Link href="/">Go Back</Link>
      </TextLink>
    </div>
  );
};

export default Custom404;
